import { apiv1 } from "@/request/apiv1";
import qs from "qs";
import { downloadFile } from "@/utils/common";
import { ShowApiError } from "@/request/error";
import axios from "axios";
import mimeType from "@/utils/mimeType";

// 获取所有的文件
export function GetFilesAll() {
    return new Promise((resolve, reject) => {
        apiv1.get(`/v1/files/all`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 获取所有的文件
export function GetFilesList(params = {}) {
    return new Promise((resolve, reject) => {
        apiv1.get(`/v1/files/page?${qs.stringify(params, {
            arrayFormat: "repeat"
        })}`)
            .then(res => {
                if (res.status === 200 || res.status === 206) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 根据id获取具体的文件信息
export function GetFilesDetailById(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/v1/files/${id}`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 根据id获取具体的文件二进制信息
export function GetFilesDataById(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/v1/files/${id}/download`, {
            responseType: 'arraybuffer',
        })
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 新增文件信息
export function PostFiles(params = {}) {
    return new Promise((resolve, reject) => {
        apiv1.post(`/v1/files`, params)
            .then(res => {
                if (res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 删除指定文件
export function DeleteFilesById(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.delete(`/v1/files/${id}`)
            .then(res => {
                if (res.status === 204) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 批量删除
export function MultipleDeleteFiles(multipleSelectieIds = []) {
    return new Promise((resolve, reject) => {
        let multipleRequests = []
        multipleSelectieIds.forEach(id => {
            multipleRequests.push(apiv1.delete(`/v1/files/${id}`))
        })
        axios.all(multipleRequests)
            .then(axios.spread(() => {
                resolve();
            })).catch(err => {
                reject(err)
            });
    })
}


// 根据id获取具体的文件并下载
export function OnFilesDownloadById(fileId = "", fileName = "未命名文件") {
    GetFilesDataById(fileId)
        .then((res) => {
            let fileTarget = mimeType.find(item => item.mime === res.headers["content-type"]);
            let fileSuffix = fileTarget ? "" : fileTarget.suffix;
            downloadFile(res.data, res.headers["content-type"], `${fileName}${fileSuffix}`);
        })
        .catch((err) => {
            ShowApiError("文件下载错误", err);
        });
}