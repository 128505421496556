<template>
  <span class="custom-check-box" @click.stop="onClick">
    <i class="el-icon-check" v-if="isShow"></i>
  </span>
</template>

<script>
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  methods:{
    onClick(){
      this.$emit("handleClick")
    }
  }
};
</script>

<style lang="scss" scoped>
.custom-check-box {
  display: inline-block;
  width: 15px;
  height: 15px;
  text-align: center;
  line-height: 15px;
  border: 1px solid #dddcdc;
  background-color: #f1f1f1;
  border-radius: 2px;
  cursor: pointer;
  overflow: hidden;
}
</style>