<template>
  <section class="files-main-wrap base-shadow">
    <header class="file-wrap-header">
      <div class="left">
        <el-row :gutter="10">
          <el-col :sm="24" :md="8">
            <el-input
              v-model="filterName"
              placeholder="请输入文件名称搜索"
              clearable
              style="width: 100%; max-width: 250px"
              size="small"
              class="mt-r"
              :disabled="showUploader"
              @change="onRefresh"
            >
              <el-button slot="append" icon="el-icon-search"></el-button
            ></el-input>
          </el-col>
          <el-col :sm="24" :md="8">
            <el-date-picker
              v-model="createdAt"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              style="width: 100%; max-width: 250px"
              :default-time="['00:00:00', '23:59:59']"
              size="small"
              :disabled="showUploader"
              @change="onRefresh"
            >
            </el-date-picker>
          </el-col>
        </el-row>
      </div>
      <div class="right">
        <el-button size="small" @click="handleUploder" v-show="!showUploader"
          >上传<i class="el-icon-upload el-icon--right"></i
        ></el-button>
      </div>
    </header>
    <main class="file-content">
      <keep-alive>
        <div class="upload-panel" :class="[showUploader ? 'active' : '']">
          <!-- <FilesUpload v-model="tempFilesList" /> -->
          <FilesUploader @close="onUploaderClose" />
        </div>
      </keep-alive>
      <div class="file-content-list">
        <template v-for="item in filesList">
          <div class="file-content-list-item" :key="item.id">
            <FilesItem
            
              showDelete
              :item="item"
              :isCheck="selectId.includes(item.id)"
              @onDelete="onFileDelete(item)"
              @onCheck="onSelect(item)"
            />
          </div>
        </template>
      </div>
    </main>
    <div class="file-wrap-pagenation">
      <el-pagination
        v-show="!showUploader"
        layout="prev, pager, next"
        @current-change="handleCurrentChange"
        :current-page="page + 1"
        :page-size="size"
        :total="total"
      >
      </el-pagination>
    </div>
    <footer class="file-wrap-footer" v-show="!showUploader">
      <div class="left">
        <CusTomCheckBox
          :isShow="selectId.length > 0"
          @handleClick="onCheckAll"
        />
        <span style="padding-left: 5px">已选 {{ selectId.length }}</span>
        <el-button
          v-if="checkPermission(['FILE_ADMIN'])"
          type="danger"
          size="small"
          class="mt-l"
          @click="onMultipleDelete"
          :disabled="selectItems.length <= 0"
          :loading="deleteLoading"
          >批量删除 {{ selectItems.length }}</el-button
        >
      </div>
      <div class="right">
        <el-button size="small" @click="onRefresh" icon="el-icon-refresh" title="点击刷新"></el-button>
        <el-button size="small" @click="onClose" v-if="showSelectBtn">关 闭</el-button>
        <el-button type="primary" size="small" @click="onConfirm" v-if="showSelectBtn"
          >确定</el-button
        >
      </div>
    </footer>
  </section>
</template>

<script>
import { GetFilesList, DeleteFilesById, MultipleDeleteFiles } from "./api";
import { ShowApiError } from "@/request/error";
import CusTomCheckBox from "@/components/CusTomCheckBox";
import FilesItem from "./components/FilesItem";
import FilesUploader from "./components/FilesUploader";
import { getFileSizeName, getFileTypeName } from "@/utils/common";
import { GetFileById } from "@/utils/auth";
import { checkPermission } from "@/utils/auth";
import { GetPageAfterRowChange } from "@/utils/table";
export default {
  props: {
    fileItems: {
      type: Array,
      default: () => {
        return [];
      },
    },
    showSelectBtn:{
      type: Boolean,
      default: true
    }
  },
  components: {
    CusTomCheckBox,
    FilesUploader,
    FilesItem,
  },
  data() {
    return {
      showUploader: false,
      loading: false,
      deleteLoading: false,
      page: 0,
      size: 10,
      filesList: [],
      total: 0,
      filterName: "",
      createdAt: null,
      selectId: [],
      selectItems: [],
      tempFilesList: [],
      imgListUrl: [],
    };
  },
  created() {
    this.getFilesList();
  },
  watch: {
    selectItems: {
      handler(val) {
        if (val.length <= 0) {
          this.selectId = [];
        } else {
          this.selectId = val.map((item) => item.id);
        }
      },
      immediate: true,
      deep: true,
    },
    selectId: {
      handler() {},
      immediate: true,
      deep: true,
    },
    fileItems: {
      handler() {
        if (this.fileItems.length > 0) {
          this.selectItems = this.fileItems;
        } else {
          this.selectItems = [];
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    checkPermission,
    getFileSizeName,
    getFileTypeName,
    GetFileById,
    handleCurrentChange(val) {
      this.page = val - 1;
      this.getFilesList();
    },
    getFilesList() {
      this.loading = true;
      GetFilesList({
        page: this.page,
        size: this.size,
        name: this.filterName,
        createdAt: this.createdAt,
      })
        .then((res) => {
          let { content, totalElements } = res.data;
          this.filesList = content;
          this.total = totalElements;
          // 加载状态结束
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          ShowApiError("获取文件列表失败", err);
        });
    },
    handleUploder() {
      this.showUploader = !this.showUploader;
    },
    onConfirmUpload() {
      this.showUploader = false;
      this.tempFilesList = [];
      this.page = 0;
      this.getFilesList();
    },
    onSelect(item) {
      if (this.selectId.includes(item.id)) {
        this.selectItems = this.selectItems.filter(
          (target) => target.id !== item.id
        );
      } else {
        this.selectItems.push(item);
      }
    },
    onClose() {
      this.$emit("cancel");
    },
    onConfirm() {
      this.$emit("confirm", { ids: this.selectId, items: this.selectItems });
    },
    clearSelect() {
      this.selectId = [];
      this.selectItems = [];
    },
    onCheckAll() {
      if (this.selectItems.length <= 0) {
        this.selectItems = [];
        this.filesList.forEach((item) => {
          this.selectItems.push(item);
        });
      } else {
        this.selectItems = [];
      }
    },
    onRefresh() {
      this.getFilesList();
    },
    onUploaderClose() {
      this.showUploader = false;
      this.onRefresh();
    },
    removeItemFormTableData(ids = []) {
      if (ids.length <= 0) {
        return false;
      }
      this.filesList = this.filesList.filter((item) => {
        return !ids.includes(item.id);
      });
      this.total = this.total - ids.length;
      this.page = GetPageAfterRowChange(
        this.page,
        this.size,
        this.filesList,
        this.total
      );
      this.onRefresh();
    },
    onFileDelete(item) {
      this.$msgbox({
        title: "删除提示",
        message: `是否确定要删除文件【${item.name}】`,
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "执行中...";
            DeleteFilesById(item.id)
              .then(() => {
                this.$message.success("操作成功!");
                instance.confirmButtonLoading = false;
                instance.confirmButtonText = "";
                done();
                this.onRefresh();
              })
              .catch((err) => {
                done();
                instance.confirmButtonLoading = false;
                instance.confirmButtonText = "";
                ShowApiError("操作请求错误", err);
              });
          } else {
            done();
          }
        },
      });
    },
    formatItemIds() {
      // 批量删除数据里，筛选出允许删除的数据和不可删除的数据
      let ids = [];
      let names = [];
      let unableDeletes = [];
      this.selectItems.forEach((item) => {
        ids.push(item.id);
          names.push(item.name);
        // if (item.canDelete) {
        //   ids.push(item.id);
        //   names.push(item.name);
        // } else {
        //   unableDeletes.push(item);
        // }
      });
      let nameString = "";
      nameString = names.toString();
      return {
        ids,
        nameString,
        unableDeletes,
      };
    },
    onMultipleDelete() {
      if (this.selectItems.length <= 0) {
        return false;
      }
      let { ids, nameString } = this.formatItemIds();
      const h = this.$createElement;
      let message = h(
        "p",
        { style: "word-break:break-all" },
        `是否确定要删除【${nameString}】？`
      );
      this.$msgbox({
        title: "删除提示",
        message: message,
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            this.deleteLoading = true;
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "执行中...";
            MultipleDeleteFiles(ids)
              .then(() => {
                this.$message.success(
                  `已成功删除${ids.length}条`
                );
                instance.confirmButtonLoading = false;
                instance.confirmButtonText = "";
                this.deleteLoading = false;
                done();
                this.selectItems = [];
                this.removeItemFormTableData(ids);
              })
              .catch((err) => {
                done();
                instance.confirmButtonLoading = false;
                this.deleteLoading = false;
                instance.confirmButtonText = "";
                ShowApiError("操作请求错误", err);
              });
          } else {
            done();
          }
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$contentHeight: 480px;
.files-main-wrap {
  box-sizing: border-box;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #fff;
  padding: 10px;
  position: relative;
}
.file-wrap-header {
  box-sizing: border-box;
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f1f1f1;
  overflow: hidden;
  .left {
    flex: 1;
  }
  .right {
    flex: 180px 0 0;
    text-align: right;
  }
}

.file-content {
  box-sizing: border-box;
  width: 100%;
  height: $contentHeight;
  text-align: left;
  position: relative;
  overflow: hidden;
  .file-content-list {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 10px;
    padding-left: 15px;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    .file-content-list-item {
      box-sizing: border-box;
      min-width: 150px;
      max-width: 180px;
      padding: 0 17px;
      text-align: center;
    }
  }
}
.upload-panel {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #fff, $alpha: 0.92);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  transform: translateY(-100%);
  transition: all 0.3s linear;
  overflow-x: hidden;
  overflow-y: auto;
  &.active {
    transform: translateY(0%);
  }
}
.file-wrap-pagenation {
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  border-top: 1px solid #f1f1f1;
}
.file-wrap-footer {
  display: flex;
  justify-content: space-between;
  padding-top: 8px;

  .left {
    flex: 1;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .right {
    flex: 1;
    text-align: right;
  }
}
</style>