<template>
  <span v-loading="loading"
    ><svg-icon :iconClass="getFileIconName(currentFileType)"></svg-icon
  ></span>
</template>

<script>
import mimeType from "@/utils/mimeType";
import { GetFilesDataById } from "../api";
export default {
  props: {
    fileId: {
      type: String,
      default: "",
    },
    fileType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      currentFileType: "",
    };
  },
  watch: {
    fileType: {
      handler() {
        if (this.fileType !== "") {
          this.currentFileType = this.fileType;
        } else {
          if (this.fileId !== "") {
            this.getFileType(this.fileId);
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    getFileType(fileId = "") {
      this.loading = true;
      GetFilesDataById(fileId)
        .then((res) => {
          this.loading = false;
          console.log('file', res)
          this.currentFileType = res.headers["content-type"];
        })
        .catch((err) => {
          this.loading = false;
          this.currentFileType = "";
          console.error(err);
        });
    },
    getFileIconName(fileType = "") {
      let result = mimeType.find((item) => item.mime === fileType);
      if (result) {
        return result.iconName;
      } else {
        return "unknownFile";
      }
    },
  },
};
</script>

<style>
</style>