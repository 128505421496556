<template>
  <div class="file-item" :class="[isCheck ? 'active' : '']">
    <CusTomCheckBox
      v-if="showCheckBox"
      class="file-item-check-box"
      :isShow="isCheck"
      @handleClick="onSelect(item)"
    />
    <keep-alive>
      <div class="file-icon-wrap">
        <el-image
          v-if="getFileTypeName(item.contentType) == 'img'"
          style="max-width: 100%"
          :src="GetFileById(item.id)"
          :z-index="4000"
          :preview-src-list="[GetFileById(item.id)]"
        >
        </el-image>
        <FilesIcon
          v-else
          style="font-size: 100px"
          :fileId="item.fileName"
          :fileType="item.contentType"
        />
      </div>
    </keep-alive>
    <div class="file-name" :title="item.name" v-if="showFileName">
      {{ item.name }}
    </div>
    <div class="file-info" v-if="showFileDesc">
      <span>{{ getFileSizeName(item.contentLength) }}</span>
      <span>{{ getFileTypeName(item.contentType) }}</span>
    </div>
    <div class="float-control">
      <el-button
        title="点击删除"
        type="text"
        icon="el-icon-delete"
        v-if="showDelete"
        @click="onFileDelete(item)"
      ></el-button>
      <el-button
        title="点击下载"
        v-if="showDownload"
        type="text"
        icon="el-icon-download"
        @click="OnFilesDownloadById(item.id, item.name)"
      ></el-button>
      <!-- <i class="el-icon-delete" @click="onFileDelete(item)"></i> -->
    </div>
  </div>
</template>

<script>
import { getFileSizeName, getFileTypeName } from "@/utils/common";
import { GetFileById } from "@/utils/auth";
import FilesIcon from "./FilesIcon";
import CusTomCheckBox from "@/components/CusTomCheckBox";
import { OnFilesDownloadById } from "../api";
export default {
  components: {
    FilesIcon,
    CusTomCheckBox,
  },
  props: {
    showFileName: {
      type: Boolean,
      default: true,
    },
    showFileDesc: {
      type: Boolean,
      default: true,
    },
    showDelete: {
      type: Boolean,
      default: false,
    },
    showDownload: {
      type: Boolean,
      default: true,
    },
    isCheck: {
      type: Boolean,
      default: false,
    },
    showCheckBox: {
      type: Boolean,
      default: true,
    },
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    OnFilesDownloadById,
    GetFileById,
    getFileSizeName,
    getFileTypeName,
    onFileDelete(item) {
      this.$emit("onDelete", item);
    },
    onSelect(item) {
      this.$emit("onCheck", item);
    },
  },
};
</script>

<style lang="scss" scoped>
.file-item {
  display: inline-block;
  box-sizing: border-box;
  text-align: center;
  width: 130px;
  max-height: 200px;
  padding: 5px;
  border: 1px solid transparent;
  position: relative;
  margin-bottom: 20px;
  border-radius: 4px;
  overflow: hidden;
  &:hover {
    border: 1px solid #ddd;
  }
  &.active {
    border: 1px solid #727272;
  }
  .file-item-check-box {
    position: absolute;
    top: 2px;
    left: 2px;
    z-index: 2;
  }
  .file-icon-wrap {
    width: 100%;
    height: 115px;
    text-align: center;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    overflow: hidden;
  }
  .file-name {
    box-sizing: border-box;
    font-size: 13px;
    height: 44px;
    line-height: 20px;
    color: #000;
    text-overflow: -o-ellipsis-lastline;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .file-info {
    box-sizing: border-box;
    font-size: 12px;
    color: #000;
    display: flex;
    justify-content: space-between;
    height: 20px;
    line-height: 20px;
    overflow: hidden;
  }
  .float-control {
    box-sizing: border-box;
    width: 100%;
    background-color: #f1f1f1;
    height: 30px;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 15px;
    color: #fff;
    transition: all 0.3s linear;
    transform: translateY(30px);
    display: flex;
    align-items: center;
    justify-content: center;
    .el-icon-delete {
      cursor: pointer;
      font-size: 16px;
    }
  }
  &:hover {
    .float-control {
      transform: translateY(0);
    }
  }
}
</style>